import React, { useContext } from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import { Col, Row } from "react-bootstrap"
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight } from "@fortawesome/free-solid-svg-icons"
import { myContext } from "../ThemeContext"

const Section = styled.section`
  margin: 1rem 0;
  .banner-image {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
  }
`

const CTAButton = styled.div`
  margin-bottom: 2rem;
`

const ButtonStyle = styled.div`
  a {
    background-color: ${props => props.secondaryBlue};
    padding: 10px 30px;
    border-radius: 2em;
    font-weight: 400;
    color: white;
    border: 1px solid transparent;
    transition: all 0.2s ease-in-out;
    font-family: "Roboto", sans-serif;
    &:hover {
      background-color: white;
      color: ${props => props.primaryBlue};
    }
    font-size: 16px;
  }
  svg {
    margin-right: 0;
    margin-left: 10px;
    max-width: 19px;
    max-height: 22px;
  }
`

const Image = styled.div`
  height: 100%;
`

const Text = styled.div`
  background-color: #0e2b89;
  position: relative;
  padding: 50px;
  h3,
  p {
    color: #fff;
  }
  @media (max-width: 768px) {
    padding: 2rem;
  }
  height: 100%;
`

export function Banner(props) {
  const theme = useContext(myContext)

  return (
    <Section>
      <Row className="no-gutters">
        <Col lg="3">
          <Image>
            <Img fluid={props.image} className="banner-image" alt="null" />
          </Image>
        </Col>
        <Col lg="9">
          <Text>
            <div className="layer-content position-relative">
              <CTAButton>
                <h3>{props.title}</h3>
                {props.description ? <p>{props.description}</p> : ""}
              </CTAButton>
              <ButtonStyle
                primaryBlue={theme.primaryBlue}
                secondaryBlue={theme.secondaryBlue}
              >
                <Link to={props.link.to}>
                  {props.link.text}
                  <FontAwesomeIcon icon={faArrowRight} size="lg" />
                </Link>
              </ButtonStyle>
            </div>
          </Text>
        </Col>
      </Row>
    </Section>
  )
}
