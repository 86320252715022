import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import CarouselBanner from "../OffersCarousel"

export default function Offers() {
  const images = useStaticQuery(graphql`
    query businessOffers {
      offer1: file(
        relativePath: { eq: "Offers/business/Business laptops.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1160) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      offer2: file(
        relativePath: {
          eq: "Offers/business/Business personalised sanitising station.png"
        }
      ) {
        childImageSharp {
          fluid(maxWidth: 1160) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      offer3: file(
        relativePath: { eq: "Offers/business/Business Touchscreen.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1160) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const OfferImages = [
    {
      image: images.offer1.childImageSharp.fluid,
      altText: "offer no.1",
    },
    {
      image: images.offer3.childImageSharp.fluid,
      altText: "offer no.3",
    },
    {
      image: images.offer2.childImageSharp.fluid,
      altText: "offer no.2",
    },
  ]

  return <CarouselBanner CarouselBannerImages={OfferImages} />
}
