import React, { useContext } from "react"
import { Col, Row } from "react-bootstrap"
import styled from "styled-components"
import Img from "gatsby-image"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCircle } from "@fortawesome/free-solid-svg-icons"
import { myContext } from "../ThemeContext"

export default function FeatureImageWithDescription({
  title,
  subtitle,
  listDescription,
  image,
}) {
  const theme = useContext(myContext)

  return (
    <Component>
      <Row>
        <Col lg="6">
          <TechnologyRefreshImage theme={theme}>
            <Img
              fluid={image}
              className="ttm-bg-layer-inner"
              alt="Library apple imac laboratory suite"
            />
          </TechnologyRefreshImage>
        </Col>
        <Col lg="6" className="z-9">
          <TechnologyRefreshText theme={theme}>
            {subtitle ? <h5>{subtitle}</h5> : ""}
            {title ? (
              <h2 className="title" style={{ color: "white" }}>
                {title}
              </h2>
            ) : (
              ""
            )}
            <ul>
              {listDescription.map((item, index) => (
                <li key={index}>
                  <FontAwesomeIcon icon={faCircle} color="white" size="sm" />
                  <span>{item}</span>
                </li>
              ))}
            </ul>
          </TechnologyRefreshText>
        </Col>
      </Row>
    </Component>
  )
}

const Component = styled.div`
  margin: 4rem 0 6rem 0;
`
const TechnologyRefreshImage = styled.div`
  @media (min-width: 1200px) {
    margin-left: -500px;
    margin-right: -570px;
    max-height: 600px;
    img {
      max-height: 600px;
    }
  }
`

const TechnologyRefreshText = styled.div`
  @media (max-width: 991px) {
    padding: 40px 15px;
  }
  @media only screen and (max-width: 1199px) {
    padding: 50px 30px;
    margin: 0;
  }
  @media (min-width: 1200px) {
    min-height: 600px;
  }
  margin-top: 80px;
  padding: 70px;
  h5 {
    color: white;
  }
  background-color: ${props => props.theme.primaryBlue};
  font-family: "Roboto", sans-serif;
  ul {
    list-style: none;
    padding: 0;
    li {
      color: white;
      font-size: 16px;
      line-height: 20px;
      padding-left: 22px;
      list-style: none;
      position: relative;
      padding-bottom: 10px;
      svg {
        position: absolute;
        left: 0;
        top: 5px;
      }
    }
  }
`
