import React, { useContext } from "react"
import Layout from "../../components/Layout"
import { graphql, useStaticQuery } from "gatsby"
import { Container, Col, Row } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faBalanceScale,
  faBullseye,
  faCalendarPlus,
  faChartLine,
  faCheck,
  faCoins,
  faCreditCard,
  faDesktop,
  faSyncAlt,
  faThumbsUp,
  faTimes,
} from "@fortawesome/free-solid-svg-icons"
import styled from "styled-components"
import PartnerCarousel from "../../components/PartnerCarousel"
import SectionTitle from "../../components/SectionTitle"
import IconCards from "../../components/business/IconCards"
import { Banner } from "../../components/Banner"
import { myContext } from "../../ThemeContext"
import FeatureImageWithDescription from "../../components/FeatureImageWithDescription"
import BusinessOffers from "../../components/business/BusinessOffers"
import SEO from "../../components/seo"

export default function Leasing() {
  const data = useStaticQuery(graphql`
    query businessLeasing {
      BannerImage: file(
        relativePath: { eq: "banner/AdobeStock_277502354.jpeg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 400, maxHeight: 250) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      TechnologyRefresh: file(
        relativePath: { eq: "AdobeStock_352896551.jpeg" }
      ) {
        childImageSharp {
          fluid {
            aspectRatio
            base64
            sizes
            src
            srcSet
          }
        }
      }
    }
  `)

  const BenefitsOfACompuleaseRental = [
    {
      icon: <FontAwesomeIcon icon={faCoins} />,
      title: "Tax Benefits",
      description: (
        <>
          <p>
            When it comes to tax benefits, there is no doubt that you are
            significantly better off by choosing to lease. When you purchase
            outright, although you do get some tax relief, it is much less than
            the allowances you receive on a computer lease arrangement.
          </p>
          <p>
            All computer rental payments are 100% allowable against tax, unlike
            other methods of acquisition, such as cash purchase for example,
            where only 25% of the price may be offset against tax in the first
            year.
          </p>
        </>
      ),
    },
    {
      icon: <FontAwesomeIcon icon={faChartLine} />,
      title: "No Capital Budget Limitations",
      description: (
        <>
          <p>
            Computer Leasing allows your business to get what it needs not what
            the capital budget will allow.
          </p>
          <p>
            If you have a capital budget of £50,000 a year for three years, it
            is only possible for to purchase £50,000 of equipment each year. By
            using Compulease™, you can equip your business with over £135,000 of
            equipment right now
          </p>
        </>
      ),
    },
    {
      icon: <FontAwesomeIcon icon={faCalendarPlus} />,
      title: "Flexible Payment Options",
      description: (
        <>
          <p>
            Compulease™ provides a complete range of flexible payment plans for
            your computer finance to suit your individual budgetary requirements
            and payments.
          </p>
          <p>
            Agreements can be over a period of your choice, between one to seven
            years and repayments can be made monthly, quarterly, bi-annually or
            annually, which ever is most convenient for you.
          </p>
        </>
      ),
    },
    {
      icon: <FontAwesomeIcon icon={faSyncAlt} />,
      title: "Upgradability",
      description:
        "The customer is never left with obsolete equipment as they can upgrade throughout the lease period to ensure they always have the very latest equipment.",
    },
    {
      icon: <FontAwesomeIcon icon={faBalanceScale} />,
      title: "Hedge Against Inflation",
      description:
        "Computer rental payments are fixed for the full period of the lease. This enables accurate forward budgeting and the luxury of paying for today’s equipment with tomorrow’s devalued money.",
    },
    {
      icon: <FontAwesomeIcon icon={faBullseye} />,
      title: "Allows Accurate Forward Planning",
      description:
        "As the computer rentals are fixed for the term of the agreement which allows you to budget accurately your outgoings and avoid any unnecessary capital outlay",
    },
    {
      icon: <FontAwesomeIcon icon={faCreditCard} />,
      title: "Preserves Existing Credit Facilities",
      description:
        "Bank and other loan arrangements remain intact because computer leasing preserves existing credit lines.",
    },
    {
      icon: <FontAwesomeIcon icon={faThumbsUp} />,
      title: "No Capital Outlay",
      description:
        "The very latest equipment can be obtained without utilising valuable working capital, thereby leaving money available for more profitable use within your business.",
    },
  ]

  const ComputerRefreshBenefits = [
    {
      icon: <FontAwesomeIcon icon={faCalendarPlus} />,
      title: "Total Flexibility",
      description: (
        <>
          <p>
            The IT needs within any business environment are constantly changing
            and future demands are hard to predict. Increasing constraints on
            budgets also mean that financial resources need to be stretched even
            further.
          </p>
          <p>
            As a result, businesses have to find new ways to get the most out of
            their budgets. The Compulease™ program provides a flexible and
            alternative approach to financing your entire IT requirement,
            enabling you to maintain, upgrade and build on your existing
            resources.
          </p>
          <p>
            Your Compulease™ agreement is totally flexible and can be tailored
            to suit your specific budget profile. At any time after during your
            computer finance agreement, you may have more equipment without
            actually increasing your repayments.
          </p>
        </>
      ),
    },
    {
      icon: <FontAwesomeIcon icon={faChartLine} />,
      title: "Protect against Equipment Obsolescence",
      description:
        "The rapid rate at which technology develops and changes creates an ongoing problem for businesses. All too often they find themselves paying for outdated technology and whilst some attempted to overcome this issue by outright purchasing their equipment, many find that this places an unnecessary burden upon capital resources.",
    },
    {
      icon: <FontAwesomeIcon icon={faDesktop} />,
      title: "Latest Computer Technology",
      description: (
        <>
          <p>
            Compulease™ is the most cost-effective way to finance capital
            equipment and it has been designed specifically so that a company
            can equip itself with latest technology without incurring additional
            ongoing expense.
          </p>
          <p>
            The Compulease™ plan allows a company to accurately budget its
            expenditure on IT as the rental remains constant throughout and the
            fact that the equipment can be updated throughout the agreement
            means that your business should always be equipped with the very
            latest technology.
          </p>
        </>
      ),
    },
    {
      icon: <FontAwesomeIcon icon={faThumbsUp} />,
      title: "Keep Your Previous Equipment",
      description:
        "When you update your computer equipment with Compulease™, you retain your original equipment FREE of charge, allowing you to re-deploy the original equipment as you see fit.",
    },
    {
      icon: <FontAwesomeIcon icon={faSyncAlt} />,
      title: "Technology Refresh",
      description:
        "Your Compulease™ agreement is totally flexible and can be tailored to suit your specific budget profile. At any time during your agreement, you may have more equipment without actually increasing your repayments.",
    },
  ]

  const PageIntro = styled.div`
    h3,
    p {
      text-align: center;
    }
    h3 {
      color: #2b53d6;
    }
    p {
      font-style: italic;
    }
  `

  return (
    <Layout headerType="business">
      <SEO title="Compulease · Benefits of Leasing Business" />
      <BusinessOffers />
      <Container>
        <SectionTitle
          title="Benefits of Computer Leasing for UK Businesses"
          description={
            <PageIntro>
              <h3>
                Leasing is the most popular method of equipment acquisition for
                sole traders, partnerships, local authorities, private and
                public companies alike
              </h3>
              <p>
                "No business would dream of paying for an “asset” like an
                employee three years in advance, so why pay for your IT
                equipment upfront when your business is going to derive the
                benefits over the next 3 years"
              </p>
              <p>Jeff Taylor, FD Manufacturing</p>
            </PageIntro>
          }
        />
      </Container>
      <IconCards
        title="Benefits of a Compulease™ Agreement"
        cards={BenefitsOfACompuleaseRental}
      />
      <PurchaseVsLease />
      <Container>
        <Banner
          image={data.BannerImage.childImageSharp.fluid}
          title="Your Compulease agreement can include hardware, software, installation and even training"
          description="We can also provide a “software only” solution, so that you can take advantage of all the benefits even if there’s no hardware involved"
          link={{
            text: "Get in touch",
            to: "/business/ContactUs/",
          }}
        />
      </Container>
      <IconCards
        title="Technology Refresh and Upgrade Benefits"
        cards={ComputerRefreshBenefits}
      />
      <Container>
        <FeatureImageWithDescription
          title="How does Technology Refresh work?"
          subtitle="Why Lease With Compulease"
          listDescription={[
            "Technology Refresh looks at the number of payments you have made under your current agreement and provides an “available spend figure” where you can acquire further equipment whilst maintaining the same rental figure by refreshing your current contract over an extended term.",
            "The extended term is normally equal to the original term, less the balance of the period outstanding for the current agreement. However, this is also flexible in the event you require more equipment than your current refresh figure allows.",
          ]}
          image={data.TechnologyRefresh.childImageSharp.fluid}
        />
        <PartnerCarousel type="business" />
      </Container>
    </Layout>
  )
}

const PurchaseVsLeaseSection = styled.div`
  margin-bottom: 5rem;
  h3 {
    text-align: center;
  }
`
const Cards = styled.div`
  margin: 1rem 0;
  @media (min-width: 992px) {
    padding: 0 6rem;
  }
`
const Card = styled.div`
  @media (max-width: 576px) {
    margin-top: ${props => (props.alt ? "1rem" : 0)};
  }
  padding: 1rem 3rem 2rem 3rem;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  h4 {
    padding: 1rem 0;
    text-align: center;
  }
  background-color: ${props => props.theme.grey};
  height: 100%;
`
const CardItem = styled.div`
  margin: 5px 0;
  color: ${props => props.theme.complementBlue};
  svg {
    width: 40px;
    height: 40px;
    font-size: 36px;
    color: ${props => (props.cons ? "#f40000" : "#27AE60")};
  }
  p {
    padding-left: ${props => (props.cons ? "6px" : "14px")};
  }
`

const ComparisonTableSection = styled.div`
  margin: 4rem 0;
  text-align: center;
  color: #6e6e6e;
  th {
    font-size: 16px;
  }
  td {
    border: 1px solid grey;
  }
  h5 {
    color: ${props => props.theme.complementBlue};
    margin-top: 1rem;
  }
  td {
    text-align: left;
    font-size: 16px;
    padding: 4px;
  }
`

function PurchaseVsLease() {
  const theme = useContext(myContext)

  const cons = [
    "Capital equipment devalues quickly over time",
    "Ties up your cash in equipment, restricting opportunity to invest elsewhere in your Business",
    "Would a business dream of paying an employee three years salary in advance? No, they would pay for their employees on a monthly salary. Computer leasing works in the same way, allowing the customer to pay for the equipment as they use it",
  ]

  const pros = [
    "Immediate use of the equipment",
    'Defer your payments and pay later with our "Deferred Payment" agreement',
    "Fixed payments",
    "Flexible",
    "Technology refresh keeps you using the latest technology",
    "Positive cash flow",
    "Access to our personable customer support team",
    "Tax benefits",
  ]

  return (
    <PurchaseVsLeaseSection>
      <Container>
        <Row>
          <Col>
            <h3>Why should a business lease?</h3>
          </Col>
        </Row>
        <Cards>
          <Row>
            <Col md="6">
              <Card theme={theme}>
                <h4>Purchase</h4>
                {cons.map(item => (
                  <CardItem cons>
                    <Row>
                      <Col xs="1">
                        <FontAwesomeIcon icon={faTimes} />
                      </Col>
                      <Col>
                        <p>{item}</p>
                      </Col>
                    </Row>
                  </CardItem>
                ))}
              </Card>
            </Col>
            <Col md="6">
              <Card theme={theme} alt>
                <h4>Lease</h4>
                {pros.map(item => (
                  <CardItem>
                    <Row>
                      <Col xs="1">
                        <FontAwesomeIcon icon={faCheck} />
                      </Col>
                      <Col>
                        <p>{item}</p>
                      </Col>
                    </Row>
                  </CardItem>
                ))}
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xs="12" md={{ span: "8", offset: "2" }}>
              <ComparisonTableSection theme={theme}>
                <h4>Cash purchase vs Lease comparison</h4>
                <table>
                  <th>Cash Purchase</th>
                  <th>Compulease Lease</th>
                  <tr>
                    <td>£150,000 upfront cost</td>
                    <td>Rentals £14,998 per qtr Payable over 12 quarters</td>
                  </tr>
                  <tr>
                    <td>Year 1 £150,000 x 40% = £60,000</td>
                    <td>Year 1 4 qtrs x £14,998 = £59,992</td>
                  </tr>
                  <tr>
                    <td>Year 2 £90,000 x 25% = £22,500</td>
                    <td>Year 2 4 qrts x £1,020 = £59,992</td>
                  </tr>
                  <tr>
                    <td>Year 3 £67,500 x 25% = 16,875</td>
                    <td>Year 3 4 qrts x £1,020 = £59,992</td>
                  </tr>
                  <tr>
                    <td>Tax allowable expenditure after 3 years = £99,375</td>
                    <td>Tax allowable expenditure after 3 years = £179,976</td>
                  </tr>
                </table>
                <h5>Tax advantage of leasing over cash: £80,601</h5>
                <h5>Plus you do not have to outlay £150,000 on day 1</h5>
              </ComparisonTableSection>
            </Col>
          </Row>
        </Cards>
      </Container>
    </PurchaseVsLeaseSection>
  )
}
