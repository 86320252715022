import React, { useContext } from "react"
import styled from "styled-components"
import { myContext } from "../../ThemeContext"
import { Container, Col, Row } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faQuestion } from "@fortawesome/free-solid-svg-icons"

const IconCardsStyle1 = styled.section`
  margin: 4rem 0;
  padding: 4rem 0 2rem 0;
  background-color: ${props => props.color};
  h2 {
    color: #0e2b89;
    text-align: center;
    margin-bottom: 4rem;
  }
  .IconCard {
    margin-bottom: 2rem;
  }
`

const CardStyle1 = styled.div`
  background-color: white;
  padding: 2rem;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  height: 100%;
`

const IconStyle1 = styled.div`
  color: ${props => props.color};
  svg {
    font-size: 46px;
    width: 46px;
    height: 46px;
  }
  @media (max-width: 1200px) {
    svg {
      margin-bottom: 12px;
    }
  }
  margin-top: 10px;
`

const CardTextStyle1 = styled.div`
  @media (min-width: 1200px) {
    padding-left: 10px;
  }
  h4 {
    margin-bottom: 5px;
  }
`

export default function IconCards({ title, cards }) {
  const theme = useContext(myContext)

  function Card({ card }) {
    return (
      <Col md="6" lg="4" className="IconCard">
        <CardStyle1>
          <Row>
            <Col xl="2" lg="12">
              <IconStyle1 color={theme.primaryBlue}>
                {card.icon ? card.icon : <FontAwesomeIcon icon={faQuestion} />}
              </IconStyle1>
            </Col>
            <Col>
              <CardTextStyle1>
                <h4>{card.title}</h4>
                <p>{card.description}</p>
              </CardTextStyle1>
            </Col>
          </Row>
        </CardStyle1>
      </Col>
    )
  }

  return (
    <IconCardsStyle1 color={theme.grey}>
      <Container>
        <Row>
          <Col sm="12">
            <h2>{title}</h2>
          </Col>
          {cards.map((card, index) => {
            return <Card card={card} key={index} />
          })}
        </Row>
      </Container>
    </IconCardsStyle1>
  )
}

const CardsSectionStyle2 = styled.div`
  margin: 4rem 0;
  padding: 4rem 0;
`

export function IconCardsStyle2({ items }) {
  const theme = useContext(myContext)

  function Card({ item }) {
    const CardStyle2 = styled.div`
      background-color: white;
      padding: 3rem 2rem;
      text-align: center;
      border-bottom: 4px solid ${theme.primaryBlue};
      height: 100%;
      h3 {
        margin: 1rem 0;
      }
      svg {
        color: ${theme.primaryBlue};
        font-size: 65px;
        height: 65;
        width: 65;
      }
    `

    return (
      <CardStyle2>
        <span>{item.icon}</span>
        <h3>{item.title}</h3>
        {item.description}
      </CardStyle2>
    )
  }

  return (
    <CardsSectionStyle2>
      <Row>
        {items.map(item => (
          <Col md="4">
            <Card item={item} />
          </Col>
        ))}
      </Row>
    </CardsSectionStyle2>
  )
}
